.nf-icons {
    .nf-field-element {
        position: relative;
        &::before {
            //border: 1px;
            font-family: FontAwesome;
            font-size: 20px;
            position: absolute;
            left: 1px;
            bottom: 1px;
            height: 48px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            z-index: 1;
        }
    }
    .date-wrap,
    .number-wrap,
    .quantity-wrap,
    .email-wrap,
    .phone-wrap,
    .firstname-wrap,
    .lastname-wrap,
    .address-wrap,
    .city-wrap,
    .state-wrap,
    .zip-wrap,
    .creditcardfullname-wrap,
    .creditcardnumber-wrap,
    .creditcardcvc-wrap,
    .creditcardexpiration-wrap,
    .creditcardzip-wrap {
        .nf-field-element {
            input {
                padding-left: 60px;
            }
        }
    }
    &.right {
        .nf-field-element {
            &::before {
                left: auto;
                right: 1px;
            }
        }
        .date-wrap,
        .number-wrap,
        .quantity-wrap,
        .email-wrap,
        .phone-wrap,
        .firstname-wrap,
        .lastname-wrap,
        .address-wrap,
        .city-wrap,
        .state-wrap,
        .zip-wrap,
        .creditcardfullname-wrap,
        .creditcardnumber-wrap,
        .creditcardcvc-wrap,
        .creditcardexpiration-wrap,
        .creditcardzip-wrap {
            > div {
                input {
                    padding-left: 12px;
                    padding-right: 60px;
                }
            }
        }
    }
    .date-wrap .nf-field-element::before {
        content: "\f073";
    }
    .number-wrap .nf-field-element::before,
    .quantity-wrap > div::before {
        content: "\f292";
    }
    .email-wrap .nf-field-element::before {
        content: "\f0e0";
    }
    .phone-wrap .nf-field-element::before {
        content: "\f095";
    }
    .firstname-wrap .nf-field-element::before,
    .lastname-wrap .nf-field-element::before {
        content: "\f007";
    }
    .address-wrap .nf-field-element::before,
    .city-wrap .nf-field-element::before,
    .state-wrap .nf-field-element::before,
    .zip-wrap .nf-field-element::before {
        content: "\f041";
    }
    .creditcardfullname-wrap .nf-field-element::before,
    .creditcardnumber-wrap .nf-field-element::before,
    .creditcardcvc-wrap .nf-field-element::before,
    .creditcardexpiration-wrap .nf-field-element::before,
    .creditcardzip-wrap .nf-field-element::before {
        content: "\f283";
    }
}
