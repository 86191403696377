input[type=checkbox].nf-toggle {
    background: transparent;
    border: 0;
    box-shadow: 0 0 0;
    margin: 10px;
    height: 18px;
    appearance: none;
    position: relative;
    cursor: pointer;
    &::after {
        background: #BCBDBF;
        border: 2px solid #BCBDBF;
        border-radius: 20px;
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: 46px;
        height: 24px;
        transition: all 0.3s ease;
    }
        &::before {
        background: #fff;
        border-radius: 20px;
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 18px;
        height: 18px;
        z-index: 99;
        transition: all 0.3s ease;
    }
    &:checked {
        &::after {
            background: lightgreen;
            border: 2px solid lightgreen;
            transition: all 0.3s ease;
        }
        &::before {
            top: -1px;
            left: 20px;
            transition: all 0.3s ease;
        }
    }
    .label-right & {
        padding: 0 50px 0 0;
    }
    .label-left & {
        padding: 0;
        margin-left: 22px;
        margin-top: 20px;
    }
}
