.nf-loading-spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: rgba(208,208,208,.5);

  border-radius: 100%;
  animation: nf-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes nf-scaleout {
  0% { transform: scale(0) }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes nf-scaleout {
  0% {
    transform: scale(0);
  } 100% {
      transform: scale(1.0);
      opacity: 0;
    }
}