.listradio-wrap {
    .nf-field-element {
        label {
            position: relative;
            margin-left: 30px !important;
            &::after {
                border-radius: 50%;
                content: "";
                width: 18px;
                height: 18px;
                position: absolute;
                left: -30px;
                bottom: 1px;
                top: .05em;
            }
            &.nf-checked-label {
                &::before {
                    border-radius: 50%;
                    content: "";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    left: -26px;
                    bottom: 5px;
                    z-index: 2;
                    top: .25em;
                }
            }
        }
        input {
            display: none;
        }
    }
}
